import { Tile } from '@almbrand/tile';

export interface TileComponentProps extends LocalId {
	icon: Icon;
	backgroundColor?: BackgroundColor;
	colorComplexion?: string;
	cssClass?: string;
	contentId: number;
	properties?: ImageProperties;
	title: string;
	url: Link;
}

export const TileComponent: React.FC<TileComponentProps> = ({
	icon,
	backgroundColor,
	contentId,
	// properties,
	title,
	url,
	useReadline,
	readlineId,
}) => {
	const { altText } = icon?.properties ?? {};
	return (
		<Tile
			id={useReadline && readlineId ? readlineId : contentId}
			title={title}
			icon={icon?.url}
			altText={altText ?? `Tile Icon ${altText}`}
			url={url?.url}
			backgroundColor={backgroundColor?.cssClass}
			trackingEvent={{
				event: 'trackNavigation',
				navigationAction: title ?? '',
				navigationDestination: url?.url ?? '',
				navigationType: 'tile',
			}}
		/>
	);
};
