export interface GetFeedbackProps {
	getFeedbackId: string;
	getFeedbackCategory?: string;
	getFeedbackSubCategory?: string;
}

export const GetFeedback: React.FC<GetFeedbackProps> = ({
	getFeedbackId,
	getFeedbackCategory,
	getFeedbackSubCategory,
}) => {
	return (
		// <div className={styles.GetFeedback}>
		<div
			data-ub-in-page={getFeedbackId}
			data-ub-in-page-category={getFeedbackCategory}
			data-ub-in-page-subcategory={getFeedbackSubCategory}
		></div>
		// </div>
	);
};

//GetFeedback ID til Codan.dk: a686922bcbdb
